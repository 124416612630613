@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  min-width: '100vw';
  min-height: '100vh';
  background-image: linear-gradient(rgba(18, 49, 77, 0.15), rgba(18, 49, 77, 1)), url("./assets/main-background.svg");
  background-size: cover;
  background-position: center;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(to left, rgba(18, 49, 77, 05), rgba(18, 49, 77, 0), rgba(18, 49, 77, 05));
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.logo {
  font-family: 'Varela Round';
  font-weight: 400;
  line-height: 53px;
  letter-spacing: 0em;
  text-align: center;
}

h1 {
  font-family: 'Open Sans';
  font-weight: 700;
  line-height: 76px;
  letter-spacing: 0em;
  text-align: center;
}

.description-text {
  font-family: 'Open Sans';
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
}

.contact-text {
  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
}

.email-text {
  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
}